.menu--opened{
  #header{
    z-index: 9999;
  }
}


@keyframes ShowSubMenu {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.header--main-nav{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  height: auto;
  max-height: 100%;

  @include break-max($mob){
    display: block;
  }

  .header--main-nav-header {
    display: none;
    position: relative;
    height: 50px;
    text-align: left;
    align-items: center;
    padding: 10px 100px 10px 20px;
    background: #fff;
    border-bottom: 1px solid $second-brand-color;

    .mob--menu-name{
      font-size: $main-text;
      color: $social-link-color;
    }

    .header--mob-togg-menu{
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .modal__close-icon{
        height: 16px;
        width: 16px;
      }

      .txt-box{
        display: none;
      }
    }
  }

  .main-nav--menu{
    position: fixed;
    top: -110%;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: calc(100% - 50px);
    background: #fff;
    visibility: hidden;
    //overflow-x: hidden;
    //overflow-y: auto;

    li{
      display: block;
      min-height: 42px;
      transition: all 0.3s ease-in;
      border-radius: 0 26px 26px 0;

      &.phantom-block{
        padding: 0;
        background: transparent !important;
        border: none;
        position: relative;
        min-height: 30px;
        height: 30px;
      }
    }
  }

  .menu--link{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
    line-height: 1.3;
    font-size: $main-text;
    font-weight: 500;
    color: $dark-blue;
    padding: 4px 25px;
    text-decoration: none;

    .main-menu-icon{
      font-size: 18px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      width: 32px;
      min-width: 32px;
      height: 100%;
      color: $social-link-color;
      margin-right: 10px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.1s ease-in;

      i{
        font-weight: 300;
      }

      .ico{
        height: 18px;
      }

    }

    .ico-sub-menu{
      height: 20px;
      margin-left: auto;
      margin-right: 0;
      font-size: 20px;
      opacity: 0.8;

      svg{
        height: 20px;
      }
    }

    .menu-link--text{
      position: relative;
      left: -30px;
      display: inline-block;
      margin-right: 15px;
      opacity: 0.1;
      visibility: hidden;
      transition: all 0.2s ease-in;
    }
  }

  .submenu{
    position: fixed;
    top: 50px;
    height: calc(100% - 50px);
    box-sizing: border-box;
    visibility: visible;
    width: 100%;
    bottom: 0;
    left: auto;
    right: 0;
    background: #fff;
    opacity: 1;
    transform: translate(100%, 0%);
    z-index: 20;
    transition: transform 0.5s ease-in-out;
    display: block;
    padding: 0;


    &.opened{
      animation-delay: unset;
      animation: none;
      transform: translate(0%, 0%);
      padding-bottom: 15px;
    }

    .to-previous-step{
      position: relative;
      display: block;
      cursor: pointer;
      margin-top: -45px;
      width: calc(100% - 60px);
      margin-bottom: 35px;

      .icon{
        font-weight: 600;
        font-size: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        //width: 32px;
        //min-width: 32px;
        //height: 100%;
        width: 18px;
        min-width: 18px;
        height: 20px;
        color: $dark-blue;
        //margin-right: 10px;
        margin-right: 20px;
      }

      .to-previous-step--content{
        display: flex;
        border-radius: 0 26px 26px 0;
        background: #fff;
        align-items: center;
        justify-content: flex-start;
        height: 40px;
        position: relative;
        padding: 0 0 0 25px;
        font-family: $mulish;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $dark-blue;

        .txt{
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    ul{
      height: 100%;
      overflow-y: auto;

      @include break-max($mob){
        //height: calc(100% - 40px);
        max-height: calc(100% - 20px);
        padding: 0 10px 0 0;
      }

      li{
        width: 100%;

        .menu--link{
          padding: 4px 25px;
          font-weight: normal;

          .menu-link--text{
            margin-right: 0;
          }
        }
      }
    }

  }

  &.open{
    top: 0;

    .header--main-nav-header{
      display: flex;
    }

    .main-nav--menu{
      visibility: visible;
      top: 50px;
    }

    .menu--link{
      .main-menu-icon{
        visibility: visible;
        opacity: 1;
      }

      .menu-link--text{
        left: 0;
        opacity: 1;
        visibility: visible;
      }
    }

  }
}