@import "ui-kit--buttons";
@import "ui-kit--typography";
@import "ui-kit--forms";

.highlight {
  color: $primary-blue;
  &--red {
    color: $beauty-primary-color;
  }
}

.title{
  &.beauty{
    .highlight{
      color: $beauty-primary-color;
    }
  }
}

// Tooltip
// Add 'has-tooltip' class to a wrapper which has tooltip block inside
.has-tooltip {
  position: relative;

  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
      transition: .3s ease;
    }
  }

  .tooltip {
    opacity: 0;
    transition: .3s ease;
    &:hover {
      display: none;
    }
  }
}

.tooltip {
  position: absolute;
  min-width: 200px;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 20px;
  background: $white;
  color: $secondary-text-color;
  font-family: $noto-sans;
  font-weight: 400;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  visibility: hidden;
  z-index: 1;

  &--top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &--bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &--right {
    left: 100%;
    transform: none;
  }

  &--left {
    right: 100%;
    transform: none;
    left: auto;
  }
}

// Misc elements
.arrow {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
  position: absolute;
  min-height: 30px;
}

img {
  height: auto;
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

.section.main-top-section{
  padding-top: 65px;

  @include break-max($mob){
    padding-top: 125px;
  }
}

.section--header,
.section-header{
  text-align: center;
  margin: 0 auto 30px auto;
  max-width: 800px;


  .title {
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    align-items: baseline;

    .devider {
      display: inline-block;
      width: 100%;
    }

    .highlight{
      padding: 0 6px;
    }
  }
}

.d-none{
  display: none;
}

.video-tutorials__image-wrapper{
  position: relative;
  display: inline-block;

  .video-tutorials__play {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,- 50%);
    border-radius: 50px;
    background: rgba($dark-blue, 0.3);
    box-shadow: 2px 4px 15px 3px rgba(45, 53, 71, 0.1);
    cursor: pointer;
    text-decoration: none;
    transition: all 0.22s ease;
    z-index: 6;

    .video-tutorials__icon {
      margin-left: 3px;
      font-size: 24px;
      height: 24px;
      color: $white;
    }

    &:hover{
      background: rgba($dark-blue, 0.5);
    }
  }
}

.ui-free-label {
  padding: 3px 7px;
  font-size: 10px;
  font-weight: 400;
  font-style: italic;
  line-height: 1;
  border-radius: 4px;
  color: #00D38B;
  background: #E6FBF3;
}

.ui-trial-label {
  padding: 3px 7px;
  font-size: 10px;
  font-weight: 400;
  font-style: italic;
  line-height: 1;
  border-radius: 4px;
  color: darken($primary-sm, 6%);
  background: $category-events;
}

.promo-video__btn {
  text-decoration: none;
  border: none;
  outline: none;
}

.mb-2{
  margin-bottom: 16px;
}