html {
  scroll-behavior: smooth;
  scroll-padding-top: 85px; // Header height + 20px offset

  @media (max-width: $mob) {
    scroll-padding-top: 20px;
  }
}

body{
  font-family: $noto-sans;
  line-height: 1.6;
  color: $secondary-text-color;
  padding-top: 65px;
  font-size: $main-text;

  @include break-max($mob){
    padding-top: 0;
  }

  &.menu--opened, &.filter--opened{
    overflow: hidden;
  }

  &.filter--opened {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(37, 50, 70, 0.5);
      z-index: 1000;
      transition: 0.3s ease;
    }
  }
}

main{
  overflow: hidden;
}

::selection {
  color: $dark-blue;
  background: $primary-blue;
}

.scroll-bar-dog{
  position: fixed;
  right: 10px;
  top: 15px;
  display: none;
  height: 38px;
  width: 38px;
  text-align: center;
  line-height: 1;
  padding: 9px 0;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: -2px -2px 3.96px 0.04px rgba(86, 153, 196, 0.18);
  z-index: 9998;

  .icon{
    color: $primary-blue;
    //font-size: 20px;
    height: 21px;
  }
  &.d-none{
    visibility: hidden !important;
    display: none !important;
  }
}

.modal-open{
  .scroll-bar-dog{
    display: none !important;
  }
}


.g-recaptcha-center {
  display: flex;
  justify-content: center;
}