.modal__sb-exit-popup{
	.modal{
		&__header{
			@include break-min($mob){
				justify-content: center;
				padding-left: 40px;
				padding-right: 40px;
			}
			&-title{
				text-align: center;
				width: 100%;
				@include break-min($mob){
					padding-top: 20px;
				}
			}
		}
		&__body{
			text-align: center;
			picture{
				display: inline-block;
				margin-bottom: 15px;

				.popup-sm-banner{
					max-height: 199px;
				}
			}
			.p--secondary{
				max-width: 420px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		&__content {
			max-height: calc(100vh - 212px);
		}
		&__footer{
			.btn-bar{
				flex-direction: column;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-bottom: 35px;
				@include break-max($mob){
					padding-bottom: 30px;
				}

				.btn{
					margin-bottom: 20px;
				}
			}
		}
	}
}