.tab-switcher--header{
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tab-switcher--header-item{
    opacity: 0.5;

    &.active{
      opacity: 1;
    }
  }

  &.ui-tab-switch{
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 2px solid $second-brand-color;
    margin-bottom: 50px;

    @include break-max($tab + 1) {
      width: unset;
      margin-left: -5px;
      margin-right: -5px;
      border-bottom: none;
    }

    .tab-switcher--header-item{
      opacity: 1;

      > a{
        display: flex;
        height: 100%;
        min-height: 130px;
        min-width: 200px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        border-radius: 0;
        padding: 30px 15px 15px 15px;
        border-bottom: 3px solid transparent;
        text-decoration: none;
        color: $secondary-text-color;
        margin-bottom: -2px;

        .ico{
          color: $secondary-text-color;
          font-size: 30px;
          font-weight: 300;
          margin-bottom: 10px;
        }
      }

      &.active{
        opacity: 1;

        > a{
          color: $dark-blue;
          border-bottom: 3px solid $primary-blue;

          .ico{
            color: $primary-blue;
          }
        }
      }

      @include break-max($tab + 1) {
        margin: 5px;

        > a{
          color: $dark-blue-bg;
          background: $second-brand-color;
          border-radius: 20px;
          height: 100%;
          font-size: 16px;
          border: none;
        }


        &.active, &:hover {
          border: none;

          > a{
            background: darken($second-brand-color, 4%);
            border: none;

            .ico{
              color: $primary-blue;
            }
          }
        }

      }

      @include break-max($mob) {
        > a{
          min-width: 150px;
          border-radius: 10px;
          min-height: 50px;
          padding: 15px 10px;
          justify-content: center;
          .ico{
            display: none;
          }
        }
      }
    }
  }
}

.wrap-tabs{
  position: relative;

  .tab--item{
    display: none;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    z-index: 1;

    &.in{
      position: relative;
      visibility: visible;
      opacity: 1;
      z-index: 3;
      background: #fff;
      display: block;
    }
  }

}