.input {
  &:focus {
    outline: none;
    -webkit-appearance: none;
  }
}

fieldset {
   border: none;
   margin-left: 0;
   margin-right: 0;
   padding: 0;
 }

.label{
  color: $dark-blue;
  margin-bottom: 5px;
  display: block;
  width: 100%;
  font-size: $main-text;

  &.label--title{

  }
}

input,
textarea,
select{
  border: 1px solid #d7dfef;
  background-color: #fff;
  width: 100%;
  color: $dark-blue;
  font-weight: 400;
  padding: 0 18px 0 18px;
  font-size: $input-fz;
  outline: none;
  border-radius: 5px;
  font-family: $noto-sans;

  &:focus {
    border: 1px solid $dark-blue !important;
  }
}

@mixin placeholder-style{
  font-size: $input-fz;
  color: #abaeb5;
  font-family: $noto-sans;
  font-style: normal;
}

::-webkit-input-placeholder {
  @include placeholder-style;
}
::-moz-placeholder {
  @include placeholder-style;
}
:-ms-input-placeholder {
  @include placeholder-style;
}
input:-moz-placeholder {
  @include placeholder-style;
}

select.select,
input[type="text"],
input[type="tel"],
input[type="email"] {
  padding-top: 0;
  height: 40px;
  line-height: 40px;
  color: $dark-blue;
}

.input--search {
  height: 40px;
  padding-right: 45px;
  background: url("../../images/icons/search.svg");
  background-repeat: no-repeat;
  background-position: center right 15px;
  background-size: 20px;
}

input[type="checkbox"] {
  display: none;

  &:checked + label:after {
    opacity: 1;
  }

  ~ label {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-left: 35px;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    color: $dark-blue;
    text-align: left;
    cursor: pointer;

    &:before {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      content: '';
      left: 0;
      top: -1px;
      border: 1px solid $second-brand-color;
      border-radius: 5px;
      color: $white;
      background-color: $white;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
      @include transition;
    }

    &:after {
      display: block;
      width: 7px;
      height: 11px;
      position: absolute;
      content: '';
      left: 8px;
      top: 2px;
      transform: rotate(45deg);
      border-right: 4px solid $primary-blue;
      border-bottom: 4px solid $primary-blue;
      border-radius: 3px;
      cursor: pointer;
      opacity: 0;
      @include transition;
    }
  }
}
