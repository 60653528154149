#footer {

  .categories {
    background: #f1f6ff;
    padding: 50px 0;

    .row {
      display: grid;
      grid-template-columns: 24% 19% 19% 19% 19%;
      width: 100%;

      //@include break-max($tab) {
      //  grid-template-columns: 30% 24% 23% 22%;
      //}

      @include break-max($tab) {
        display: flex;
        flex-wrap: wrap;
      }

      .column {
        padding: 0 10px;

        &:first-child {
          grid-row-end: span 2;
        }

        &:nth-child(n+2):nth-child(-n+5) {
          margin-bottom: 30px;
        }

        @include break-max($tab) {
          max-width: 100%;
          flex-basis: 100%;
          padding: 0;
          //&:first-child {
          //  grid-row-end: span 3;
          //}
          //&:nth-child(7) {
          //  order: 1;
          //}
          //&:last-child {
          //  order: 2;
          //}
          //&:nth-child(6) {
          //  margin-bottom: 30px;
          //}
        }
        @include break-max($tab) {
          //max-width: 100%;
          //flex-basis: 100%;
          margin: 0 !important;
        }

        p {
          position: relative;
          display: inline-flex;
          align-items: center;
          margin-bottom: 15px;
          line-height: 1.3;
          transition: .3s ease;

          @include break-max($tab) {
            width: 100%;
            border-bottom: 1px solid #cdd3dd;
            padding: 5px 60px 5px 0;
            min-height: 50px;
          }

          a{
            color: $dark-blue;
            text-decoration: none;
            transition: .3s ease;

            &:hover {
              color: $primary-blue;
            }
          }

          .fa {
            &.active{
              color: $primary-blue;
              transform: rotate(180deg);

              //&:before{
              //  transform: rotate(180deg);
              //}
            }
          }


          .fa {
            position: absolute;
            //top: 0;
            top: 10px;
            right: 0;
            width: 50px;
            //height: 100%;
            height: 28px;
            display: inline-flex;
            font-size: 20px;
            padding: 0 10px;
            font-weight: 400;
            transition: .3s ease;

            //&:before{
            //  position: absolute;
            //  height: 100%;
            //  width: 100%;
            //  display: flex;
            //  align-items: center;
            //  justify-content: center;
            //  transition: .3s ease;
            //}
            @include break-min($tab + 1) {
              display: none;
            }
          }
        }

        ul {
          @include break-max($tab) {
            max-height: 0;
            margin-left: 25px;
            overflow: hidden;
            transition: .3s ease;
            &.expanded {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }

  .navigation {
    padding: 50px 0 0;

    .row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .column {
        max-width: 19%;
        flex-basis: 19%;
        padding: 0 10px;

        &:first-child {
          max-width: 24%;
          flex-basis: 24%;
        }

        @include break-max($tab) {
          padding: 0;

          &:first-child {
            max-width: 30%;
            flex-basis: 30%;
          }

          &:nth-child(2) {
            max-width: 24%;
            flex-basis: 24%;
          }

          &:nth-child(3) {
            max-width: 23%;
            flex-basis: 23%;
          }

          &:nth-child(4) {
            max-width: 22%;
            flex-basis: 22%;
          }

          &:last-child {
            max-width: 100%;
            flex-basis: 100%;
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid #f3f5f7;

            p {
              display: none;
            }

            ul {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              margin: 0 -15px;
              padding-top: 10px;

              li {
                margin: 0 15px 2px 15px;
              }
            }

          }
        }

        @include break-max($tab) {
          &:not(:last-child) {
            max-width: 50% !important;
            flex-basis: 50% !important;
          }
          &:first-child, &:nth-child(2) {
            margin-bottom: 30px;
          }
          &:last-child {
            max-width: 100%;
            flex-basis: 100%;
          }
        }

        p {
          display: inline-flex;
          align-items: center;
          margin-bottom: 15px;

          //.fa {
          //  margin-right: 15px;
          //  font-size: 20px;
          //  font-weight: 300;
          //}

          .ico{
            height: 20px;
            margin-right: 15px;
          }
        }
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 -10px;

    @include break-max($tab) {
      flex-direction: row;
      margin: 0;
    }

    @include break-max($tab) {
      flex-direction: column;
    }
  }

  li {
    margin-bottom: 10px;
    line-height: 1;

    @include break-max($tab){
      margin-bottom: 2px;
      line-height: normal;
    }

    a {
      font-size: 14px;
      color: $dark-blue;
      text-decoration: none;
      transition: .3s ease;

      @include break-max($tab){
        display: flex;
        align-items: center;
        min-height: 30px;
        padding-top: 4px;
        padding-bottom: 4px;
        line-height: 1.1;
      }

      &:hover {
        color: $primary-blue;
      }
    }
  }

  .company-social-links{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 280px;

    @include break-max($tab){
      margin: 10px auto;
      justify-content: space-around;
    }

    a{
      display: flex;
      align-items: center;
      width: 48px;
      height: 48px;
      color: $social-link-color;
      text-decoration: none;
      @include transition();

      .ico{
        //font-size: 22px;
        height: 22px;
      }

      @include break-max($tab){
        justify-content: center;

        .ico{
          //font-size: 25px;
          height: 25px;
        }
      }

      &:hover{
        color: darken($social-link-color, 6%);
      }
    }
  }

  .copyrights {
    padding: 0 0 50px;
    @include break-max($tab){
      padding-bottom: 20px;
    }

    .app-links{
      padding-top: 15px;
    }

    .container {
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px solid #f3f5f7;

      @include break-max($tab) {
        padding-top: 0;
        margin-top: 10px;
        border: none;
      }
    }

    p {
      text-align: center;
      font-size: 12px;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;

      a{
        color: $primary-blue;
        @include transition();

        &:hover{
          color: $primary-blue;
        }
      }
    }
  }
}