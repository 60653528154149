//@import "core";
@import "base/index";
@import "base/font-utils";

//Import base fonts
$font-path: '/build/fonts/';
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Regular", 400, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-SemiBold", 600, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Bold", 700, inherit, eot woff2 woff ttf otf svg);
@include font-face("Noto Sans", "#{$font-path}noto-sans/NotoSans-Regular", 400, inherit, eot woff2 woff ttf otf svg);
@include font-face("Caveat", "#{$font-path}caveat/Caveat-Bold", 700, inherit, eot woff2 woff ttf otf svg);

//header & footer
@import "modules/header-and-footer/index";

// Carousel
//@import "~slick-carousel/slick/slick";
//@import "~slick-carousel/slick/slick-theme";

//@import "modules/registration/index";