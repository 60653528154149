// use in uikit.scss

//font styles
$mulish: "Mulish", sans-serif;
$noto-sans: "Noto Sans", sans-serif;
$caveat: 'Caveat', cursive;

$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;

// Responsiveness breakpoints
$fullHD: 1920px;
$lap: 1366px;
$tab768: 768px;
$tab: 1023px;
$tab-xl: 1200px;
$tab1023: 1023px; // custom for main header responsive like in design
$mob: 767px;
$mob480: 480px;
$mob350: 350px;


// Colors
$primary-sm: #FEC439;
$primary-blue: #06adef;
$primary-red: #ff3259;
$color-green: #00D38B;
$dark-blue: #0B3052;
$brand-grey: #f1f6ff;
$secondary-text-color: #788291;
$white: #ffffff;
$second-brand-color: #f1f6ff;
$social-link-color: #ABBCCC;
$border-color: #E7ECF7;
$color-neutral-light: #F6F6F6;

$category-def: #f1f6ff;
$category-events: #fff8ea;
$category-beauty: #fdeaee;
$category-medical: #d8f4ff;
$category-sport: #f1f6ff;

$category-def-hover: #0B3052;
$category-events-hover: #fec439;
$category-beauty-hover: #ff3259;
$category-medical-hover: #06adef;

$dark-blue-bg: #0B3052;

//beauty category
$nail-second-color: #ef3558;
$beauty-primary-color: #ef3558;

// Fonts size
$h1: 40px;
$h1-secondary: 30px;
$h2: 30px;
$h2-secondary: 30px;
$h3: 20px;
$h4: 18px;
$cta-btn-text: 18px;
$main-text: 16px;
$secondary-text: 14px;
$small-text: 14px;
$cursive-text: 24px;
$input-fz: 18px;

// Font weight
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;

$br: 20px;

@mixin break-min($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin break-max($point) {
  @media (max-width: $point) {
    @content;
  }
}

@mixin transition{
  transition: all .22s ease;
}