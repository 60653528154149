header.header{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 0 20px;
  @include break-max($tab){
    padding: 0;
  }

  .header--settings-block{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background: #f1f6ff;

    @include break-max($mob){
      width: 100%;
      border-bottom: none;
      position: relative;
      padding-bottom: 25px;
      padding-top: 10px;
      background: transparent;
    }

    .header--settings-container{
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: all 1s ease;

      @include break-max($mob){
        flex-wrap: wrap;
        height: auto;
      }

      .header--togg-menu{
        display: none;

        @include break-max($mob){
          display: inline-flex;
          margin-right: 15px;
          top: 0;
        }
      }
    }

    .mobile-header--shape{
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      z-index: -1;

      @include break-max($mob){
        display: block;
      }

      svg{
        position: absolute;
        bottom: 0;
        left: 0;
        -webkit-filter: drop-shadow(0px 13px 12px rgba(86, 153, 196, 0.05));
        filter: drop-shadow(0px 13px 12px rgba(86, 153, 196, 0.05));

        path{
          fill: #fff;
        }
      }
    }
  }

  .header--logo{
    margin-right: 15px;

    @include break-max($tab){
      height: 30px;
    }

    @include break-max($mob){
      width: 100%;
      text-align: center;
      order: 10;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
    }

    .header--logo-image{
      display: block;
      max-width: 100%;
      height: 30px;

      &__winter{
        height: 34px;
      }

      @include break-max($tab){
        display: inline-block;
      }
    }
  }

  .header--navigation-block {
    position: absolute;
    left: 0;
    top: 65px;
    background: transparent;
    width: 100%;

    .header--navigation-menu-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      min-height: 65px;
      padding-top: 15px;
      padding-bottom: 15px;

      @include break-max($mob){
        min-height: unset;
        padding-top: 0;
        padding-bottom: 0;
      }

      //.header--togg-menu{
      //  margin-right: 30px;
      //
      //  @include break-max($tab){
      //    display: none;
      //  }
      //}

      .main-menu-short{
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: auto;

        .dropdown{
          position: relative;
          display: inline-block;
          margin: 0;
          padding: 0;
          .dropdown-toggle{
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            background: transparent;
            border: none;
            box-shadow: none;
            outline: none;

            .icon{
              display: inline-block;
              min-width: 12px;

              svg{
                height: 14px;
              }
            }
          }
          .dropdown-menu{
            position: absolute;
            min-width: 220px;
            left: 0;
            top: 100%;
            padding: 12px 10px;
            background: #FFFFFF;
            box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
            border-radius: 5px;
            transform: translateY(22px);
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-out;
            z-index: 999;

            .menu--link{
              display: flex;
              width: 100%;
              padding: 4px 10px;
              height: 34px;
              white-space: nowrap;
              font-size: 15px;
              background: #ffff;
              border-radius: 2px;
              color: $dark-blue;

              &:hover{
                background: $second-brand-color;
                color: $dark-blue;
              }
            }
          }

          &:hover{
            .dropdown-menu{
              visibility: visible;
              transform: translateY(0px);
              opacity: 1;
            }
          }
        }

        .dropdown > .dropdown-toggle:focus~.dropdown-menu{
          visibility: visible;
          transform: translateY(0px);
          opacity: 1;
        }

        @include break-max($mob){
          display: none;
        }
      }

      .main-menu-short .menu--link{
        font-size: 16px;
        display: inline-flex;
        align-items: center;
        height: 30px;
        padding: 0 30px 0 0;
        text-decoration: none;
        color: $dark-blue;
        transition: color 0.2s ease-in;
        line-height: 1;

        &:last-child{
          padding-right: 0;
        }

        &:hover{
          color: darken($dark-blue, 6%);
        }

        &.active{
          color: darken($dark-blue, 8%);
        }

        &-to-ua{
          text-align: left;
          font-size: 13px;
          padding-left: 34px;
          font-weight: 300;
          gap: 6px;
          background: url("../../../images/stop-war/ua-flag-2.png") no-repeat center left;
          background-size: 30px;
          b{
            //font-weight: bold;
          }

          img{
            position: static;
            margin: 0;
            max-height: 16px;
          }
        }
      }
    }
  }

  &.header--stickly{

    .header--settings-block{
      .header--togg-menu{
        @include break-max($mob){
          display: inline-flex;
          position: fixed;
          top: 15px;
          left: 15px;
          height: 50px;
          width: 50px;
          background: rgba($second-brand-color, 0.8);
          border-radius: 50%;
          justify-content: center;
          z-index: 100;
          transition: top 1s ease;
          mix-blend-mode: multiply;
        }
      }
    }

    .header--navigation-block{
      .header--navigation-menu-container{
        min-height: unset;

        .header--togg-menu,
        .main-menu-short{

        }
      }
    }
  }

  &.main-menu-short-white{
    .header--navigation-block {
      .header--navigation-menu-container {
        .main-menu-short .menu--link{
          color: $white;

          svg{
            path{
              fill: #fff;
            }
          }

          &:last-child{
            padding-right: 0;
          }

          &:hover{
            color: darken($white, 6%);
          }

          &.active{
            color: darken($white, 8%);
          }
        }
      }
    }
  }
}

.header--user-block{
  display: flex;
  margin-left: auto;
  margin-right: 0;

  .open-sub-menu-login-bar{
    display: none;
    width: 30px;
    height: 40px;
    padding: 0;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    box-shadow: none;
    outline: none;

    @include break-max($mob){
      display: inline-flex;
    }

    &:hover, &:active{
      border: none;
      background: transparent;
      box-shadow: none;
      outline: none;
    }

    .icon{
      //font-weight: bold;
      //font-size: 20px;
      color: $primary-blue;
      width: 20px;
      height: 20px;
    }
  }

  .btn{
    margin: 4px 10px;
    font-weight: 600;
    min-height: 40px;

    &.btn--login{
      padding: 0 5px;
    }

    @include break-max($mob){
      margin: 0 4px;
    }
  }

  .hide-on-mob{
    @include break-max($mob){
      display: none !important;
    }
  }
}

.header--mob-togg-menu,
.header--togg-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  text-decoration: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  height: 30px;
  color: $dark-blue;
  line-height: 1;
  transition: all 0.2s ease-in-out;
}

.header--mob-togg-menu .hamburger-box,
.header--togg-menu .hamburger-box{
  display: inline-block;
  position: relative;
  width: 30px;
  height: 28px;
  transition: .4s ease-in-out;
  margin-right: 15px;
  z-index: 1;

  @include break-max($mob){
    margin-right: 0;
    //width: 26px;
  }
}

.header--mob-togg-menu .txt-box,
.header--togg-menu .txt-box{
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;

  @include break-max($mob){
    display: none;
  }
}

.header--mob-togg-menu .hamburger-box span,
.header--togg-menu .hamburger-box span{
  display: block;
  position: absolute;
  height: 3px;
  width: 75%;
  background: $dark-blue;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .35s ease-in-out;
}

.header--mob-togg-menu .hamburger-box span:nth-child(1),
.header--togg-menu .hamburger-box span:nth-child(1) {
  top: 4px;
}

.header--mob-togg-menu .hamburger-box span:nth-child(2),
.header--togg-menu .hamburger-box span:nth-child(2) {
  width: 100%;
  top: 12px;
}
.header--mob-togg-menu:hover .hamburger-box span:nth-child(2),
.header--togg-menu:hover .hamburger-box span:nth-child(2) {
  left: -10%;
}

.header--mob-togg-menu .hamburger-box span:nth-child(3),
.header--togg-menu .hamburger-box span:nth-child(3) {
  top: 20px;
}

.header--mob-togg-menu.open .hamburger-box span:nth-child(1),
.header--togg-menu.open .hamburger-box span:nth-child(1) {
  top: 12px;
  left: 5px;
  width: 80%;
  background: $primary-red;
  transform: rotate(135deg);

  @include break-max($mob){
    left: 3px;
  }
}

.header--mob-togg-menu.open .hamburger-box span:nth-child(2),
.header--togg-menu.open .hamburger-box span:nth-child(2) {
  left: -60px;
  opacity: 0;
}

.header--mob-togg-menu.open .hamburger-box span:nth-child(3),
.header--togg-menu.open .hamburger-box span:nth-child(3) {
  top: 12px;
  left: 5px;
  width: 80%;
  background: $primary-red;
  transform: rotate(-135deg);

  @include break-max($mob){
    left: 3px;
  }
}

