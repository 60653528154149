/*!
 * Generated using the Bootstrap Customizer (https://getbootstrap.com/docs/3.4/customize/)
 */
/*!
 * Bootstrap v3.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

[role="button"] {
  cursor: pointer;
}
.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: $dark-blue;

  &.fade {
    filter: alpha(opacity=0);
    opacity: 0;
  }

  &.in {
    filter: alpha(opacity=50);
    opacity: 0.5;
  }
}


.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  &.fade{
    .modal-dialog {
      -webkit-transform: translate(0, -25%);
      -ms-transform: translate(0, -25%);
      -o-transform: translate(0, -25%);
      transform: translate(0, -25%);
      -webkit-transition: -webkit-transform 0.3s ease-out;
      -o-transition: -o-transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
    }
  }

  &.in{
    .modal-dialog {
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }

  &--video {
    @media (min-width: $mob) {
      .modal-dialog {
        width: 70% !important;
      }
    }

    @media (min-width: $mob) and (max-width: $tab) {
      .modal-dialog {
        width: 90% !important;
      }
    }
  }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0;
  }

  .modal-content {
    position: relative;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 0;
    background: $white;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);

    @include break-max($mob){
      padding-top: 50px;
    }
  }

}


.modal__header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 70px 25px 30px;

  @include break-max($mob){
    min-height: 50px;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 14px 50px 10px 20px;
    border-bottom: 1px solid $second-brand-color;
  }

  &--with-icon {
    display: flex;
    align-items: center;
    padding-top: 40px;

    .modal__header-body{
      width: 100%;
    }

    @media (max-width: $mob) {
      //flex-direction: column;
      padding: 14px 50px 10px 20px;
    }
  }

  &-icon {
    position: relative;
    margin: 0 50px 0 0;
    z-index: 1;

    @media (max-width: $mob) {
      //margin: 0 0 5px;
      display: none;
      &:before {
        display: none;
      }
    }

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: -30px;
      width: 120px;
      height: 100px;
      border-radius: 0 50px 50px 0;
      background: $second-brand-color;
      transform: translateY(-50%);
      z-index: -1;
    }

    img {
      width: 70px;
      height: 70px;

      @media (max-width: $mob) {
        display: none;
        width: 80px;
        height: 80px;
      }
    }
  }

  &-title {
    line-height: 1;
    text-align: left;

    @media (max-width: $mob) {
      display: block;
      font-size: 18px;
      text-align: left;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

 .modal__close {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
   height: 50px;
   width: 50px;
  top: 10px;
  right: 10px;
  text-align: center;
  padding: 0;
  border-radius: 3px;
  border: none;
  outline: none;
  background: transparent;
  transition: .3s ease;
  cursor: pointer;

   @include break-max($mob){
     right: 0;
     top: 0;
   }

  .modal__close-icon {
    width: 16px;
    height: 16px;
    color: $primary-red;
  }
}

.modal__body {
  position: relative;
  padding: 25px 35px 40px;

  @media (max-width: $mob) {
    padding: 15px 20px 30px;
  }

  &--video {
    padding: 0;
  }

  .modal__content {
    iframe {
      border-radius: 0 0 20px 20px;

      @include break-max($mob){
        border-radius: 0;
      }
    }

    @include break-max($mob){
      max-height: calc(100vh - 100px);
      overflow-x: hidden;
      overflow-y: auto;
    }

    &-form {
      position: relative;

      &-group {
        margin-bottom: 10px;

        .g-recaptcha {
          iframe {
            border-radius: 0;
          }
        }
      }

      &-btn {
        margin-top: 30px;
        text-align: center;
      }
    }
  }
}



.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal{
    .modal-dialog {
      width: 650px;
      margin: 30px auto;
    }

    .modal-content {
      border-radius: 20px;
    }

    .modal-sm {
      width: 500px;
    }
  }
}
@media (min-width: 992px) {
  .modal{
    .modal-lg {
      width: 800px;
    }
  }
}
.clearfix:before,
.clearfix:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}

.clearfix:after,
.modal-header:after,
.modal-footer:after {
  clear: both;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}
