.title {
  color: $dark-blue;
  letter-spacing: 0.01em;
  font-weight: $bold;
  font-family: $mulish;
  &--h1 {
    font-size: $h1;
    font-weight: $bold;
    line-height: 1.2;
    margin-bottom: 30px;

    @include break-max($tab) {
      font-size: 32px;
    }

    @include break-max($mob480) {
      font-size: 30px;
    }

    &.has-secondary-title{
      letter-spacing: 1px;
    }

    .title--secondary-h1{
      display: inline-block;
      width: 100%;
      font-size: $h1-secondary;
      font-weight: $light;

      @include break-max($tab) {
        font-size: 24px;
      }
    }


    &.has-subtitle {
      margin-bottom: 18px;
    }
  }

  &--h2 {
    font-size: $h2;
    line-height: 1.2;

    @include break-max($mob480) {
      font-size: 26px;
    }

    &-alt {
      font-size: $h2-secondary;
      font-weight: $normal;
      line-height: 1.2;
      letter-spacing: 0.1em;

      @include break-max($tab){
        font-size: 26px;
      }

      @include break-max($mob) {
        font-size: 20px;
      }
    }

    &.has-subtitle {
      margin-bottom: 10px;
    }
  }

  &--h3 {
    font-size: $h3;
    line-height: 1.3;
    letter-spacing: 0;

    &.has-subtitle {
      margin-bottom: 7px;
    }
  }

  &--h4 {
    font-size: $h4;
    line-height: 1.3;
    letter-spacing: 0;

    @include break-max($mob) {
      font-size: 16px;
    }
  }

  &--light {
    color: $white;
  }
}

.p {
  color: $dark-blue;
  font-family: $noto-sans;
  font-size: $main-text;
  font-weight: $normal;
  letter-spacing: 0;

  @include break-max($mob) {
    line-height: 1.5;
  }

  &--secondary {
    color: $secondary-text-color;
  }

  &--small {
    font-size: $small-text;
    line-height: 1.45;

    @include break-max($mob) {
      font-size: 16px;
    }
  }

  &--light {
    color: $white;
  }

  b,
  &--bold {
    font-weight: $bold;
    font-family: $mulish;
  }
}

.cursive {
  display: block;
  color: $primary-blue;
  font-size: $cursive-text;
  font-family: $caveat !important;
  font-weight: $bold;
  position: relative;
  line-height: 1.4;
  letter-spacing: 0.3px;

  &--light {
    color: $white;
  }

  &.black,
  &--dark {
    color: $dark-blue;

    .arrow{}
  }
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: $bold;
  font-family: $mulish;
}

.regular {
  font-weight: $normal;
}

.light {
  font-weight: $light;
}

.dark {
  color: $dark-blue;
}

.td-none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}