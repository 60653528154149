.header {
  .header__language {
    .header__language-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1;
      margin-right: 0;
      padding-right: 0;
      padding-left: 15px;

      .header__language-flag {
        display: inline-block;
        width: 30px;
        text-align: left;
        position: relative;
        bottom: -1px;

        @include break-max($mob) {
          width: 21px;
        }

        img {
          height: 14px;
          width: auto;
        }
      }

      .header__language-title {
        font-family: $mulish;
        font-weight: bold;
        @include break-max($tab + 1) {
          display: none;
        }
      }
    }
  }
}

.modal__body--language {

  .language__list {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;

    .language__item {
      width: calc(50% - 10px);
      margin: 5px;

      .language__item-selector {
        position: relative;

        input[type=radio] {
          display: none;
          opacity: 0;
          visibility: hidden;
        }

        label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 40px;
          padding: 0 20px;
          border-radius: 20px;
          @include transition;

          a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }
          }

          span {
            display: block;

            &.language__item-flag {
              width: 25px;
              height: 17px;
              margin-right: 10px;
            }

            &.language__item-title {
              color: $dark-blue;
            }
          }

          &:hover {
            background: $second-brand-color;
          }
        }

        input[type=radio]:checked + label {
          background: $second-brand-color;

          .language__item-title {
            font-weight: 600;
          }
        }
      }
    }
  }
}

