input[type=checkbox].custom-checkbox{
  display: none;
  height: 0;
  width: 1px;
  position: absolute;
  opacity: 0;
}

input[type=checkbox].custom-checkbox + label.label-custom-checkbox{
  cursor: pointer;
  font-weight: 400;
  position: relative;
  display: flex;
  padding-left: 42px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  min-height: 25px;
  margin-bottom: 5px;

  &:before{
    display: block;
    content: ' ';
    position: absolute;
    left: 0;
    top: -1px;
    width: 25px;
    height: 25px;
    border: 1px solid #d7dfef;
    border-radius: 5px;
    color: #fff;
    background-color: #fff;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    transition: all .3s ease;
  }

  &:after {
    display: block;
    transition: all .25s ease;
    opacity: 0;
    content: " ";
    position: absolute;
    width: 8px;
    height: 13px;
    border-right: 4px solid $primary-blue;
    border-bottom: 4px solid $primary-blue;
    border-radius: 3px;
    left: 8px;
    top: 2px;
    transform: rotate(45deg);
    cursor: pointer;
  }

  &:hover{
    &:before{
      border-color: $primary-blue;
    }
  }

}

input[type=checkbox].custom-checkbox:checked + label.label-custom-checkbox{
  &:after{
    opacity: 1;
  }
}