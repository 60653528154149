.popup--custom,
.demo--card-popup{

  .modal{
    //@include break-min(800px) {
    //  max-width: 740px;
    //}
  }

  .modal__header-title{
    padding: 15px 30px 0 30px;
    max-width: 474px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 0;

     @include break-max($mob){
       max-width: 100%;
       padding: 0;
       margin: 0;
       text-align: left;
     }
  }

  .modal__body{
    padding-top: 15px;

    @include break-max($mob){
      padding-top: 20px;
    }
  }

  .demo--card-popup-buttons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -5px;

    @include break-max(600px){
      margin: 0;
    }
  }

  .demo--card-popup-item{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(50% - 10px);
    width: 100%;
    padding: 30px 22px 22px;
    margin: 5px;
    border-radius: 20px;
    background: $second-brand-color;
    transition: .3s ease;
    color: $dark-blue;
    text-decoration: none;
    text-align: center;

    @include break-max(600px){
      width: 100%;
      max-width: 100%;
      margin: 0 0 10px 0;
    }

    &:hover {
      background: $white;
      box-shadow: 0px 0px 15px 0 rgba(45, 53, 71, 0.1);
    }


    //.ico{
    //  font-size: 26px;
    //  height: 28px;
    //  text-align: center;
    //  margin-bottom: 10px;
    //}

    svg{
      height: 27px;
      margin-bottom: 10px;

      path{
        fill: $dark-blue;
      }
    }
  }

  .demo--card-popup-name{
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 1.3;
  }

  .demo--card-popup-descr{
    font-size: 14px;
  }

  .demo--card-login-data{
    display: inline-block;
    width: 100%;
    font-size: 16px;
    line-height: 1;

    &:first-child{
      padding-top: 10px;
    }

    .demo--card-pass{
      font-weight: 600;
    }
  }
}