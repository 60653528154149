@mixin chat-br{
  border-radius: 10px 10px 0 0;
}

@keyframes support_pulse {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: .6;
    transform: scale(1.15);
  }
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
}

#supportChat-card {
  $desk-tab-height: 44px;
  $mob-tab-height: 30px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  height: $desk-tab-height;
  right: calc(50% - 600px);

  @include break-max(1215px) {
    width: inherit;
    right: 0;
  }
  @include break-max($mob) {
    text-align: center;
    bottom: 0;
  }

  #supportChat{
    box-shadow: 0 0 15px 0 rgba(38, 50, 70, 0.07);
    @include chat-br;
  }

  .cap {
    color: #fff;
    font-weight: bold;
    padding: 0 15px;
    min-height: $desk-tab-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .txt {
    width: 125px;
  }

  .supportChat-card--content {
    position: relative;
    display: inline-block;
    vertical-align: top;
    background: #FFFFFF;
    @include chat-br;
    transition: transform 0.5s;
    z-index: 100;
    &:hover,
    &.open {
      transform: translateY(calc(-100% + #{$desk-tab-height}));
      @include break-max($mob) {
        transform: translateY(calc(-100% + #{$mob-tab-height}));
      }
    }

    &.open {
      .close {
        display: block !important;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 11px;
        height: 11px;
        cursor: pointer;
      }
    }

    .cap {
      width: 200px;
      position: relative;
      //background-color: $primary-blue;
      color: $primary-blue;
      border-bottom: 1px solid $second-brand-color;
      text-align: center;
      font-weight: 300;
      font-size: 16px;
      @include chat-br;
      padding: 10px 25px 10px 60px;

      @include break-max($tab){
        width: 170px;
        max-width: 170px;
        padding: 10px 15px 10px 35px;
      }

      .ico{
        color: $primary-blue;
        position: absolute;
        //top: 0;
        top: 12px;
        left: 10px;
        width: 50px;
        //height: 100%;
        height: 24px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 600;

        @include break-max($tab){
          left: 0;
        }
      }

      .close {
       display: none;
      }
    }
    .content {
      text-align: center;
      cursor: pointer;
      .img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin: 20px auto;
        position: relative;
        background: url("../../../images/about-us/our-team/vika.jpg") no-repeat center;
        background-size: 88px;

        &:before {
          display: block;
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: -1px;
          left: -1px;
          transform: translate(-50%, -50%);
          opacity: 1;
          background-color: transparent;
          border: 1px solid #00AEF2;
          animation: support_pulse 2s infinite;
          border-radius: 50%;
        }
      }
      .txt {
        font-size: 16px;
        text-align: center;
        color: #494949;
        line-height: 1.2;
        padding: 0 5px;
        padding-bottom: 20px;
        width: 100%;
      }
    }
  }
}