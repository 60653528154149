@import "vars";
button, .btn {
  user-select: none;
}

button, .btn, a, .link {
  touch-action: manipulation;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &:focus{
    outline: 0 !important;
  }
}

a {
  color: $primary-blue;
  text-decoration: none;
}

a:active,a:focus,a:hover {
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent;
}

@mixin has-icon{
  &.btn-icon-left{
    &:before {
      display: inline-flex;
      content: '';
      width: 24px;
      height: 18px;
      margin-right: 10px;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }
    &:after{
      display: none;
    }
  }

  &.btn-icon-right{
    &:after {
      display: inline-flex;
      content: '';
      width: 24px;
      height: 18px;
      margin-left: 10px;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }
    &:before{
      display: none;
    }
  }

  &.fa-user{
    &:before,
    &:after{
      width: 18px;
      background: url("../../images/icons/btn-icon/user-solid.svg");
    }
  }
  &.fa-door-open{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/door-open-solid.svg");
    }
  }
  &.fa-door-open-black{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/door-open-solid-black.svg");
    }
  }
  &.fa-long-arrow-alt-right-white{
    &:before,
    &:after{
      width: 14px;
      background: url("../../images/icons/btn-icon/long-arrow-alt-right-solid-white.svg");
    }
  }
  &.fa-long-arrow-alt-right-blue{
    &:before,
    &:after{
      width: 14px;
      background: url("../../images/icons/btn-icon/long-arrow-alt-right-solid-blue.svg");
    }
  }
  &.fa-long-arrow-alt-right-black{
    &:before,
    &:after{
      width: 14px;
      background: url("../../images/icons/btn-icon/long-arrow-alt-right-solid-black.svg");
    }
  }
  &.fa-long-arrow-alt-left-black{
    &:before,
    &:after{
      width: 14px;
      background: url("../../images/icons/btn-icon/long-arrow-alt-right-solid-black.svg");
      transform: rotate(180deg);
    }
  }
  &.fa-arrow-down-black{
    &:before,
    &:after{
      width: 14px;
      background: url("../../images/icons/btn-icon/arrow-down-solid-black.svg");
    }
  }
  &.fa-users{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/users-solid.svg");
    }
  }
  &.fa-paper-plane{
    &:before,
    &:after{
      width: 18px;
      background: url("../../images/icons/btn-icon/paper-plane-solid.svg");
    }
  }
  &.fa-paint-brush{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/paint-brush-solid.svg");
    }
  }
  &.fa-paint-brush-black{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/paint-brush-solid-black.svg");
    }
  }
  &.fa-paint-brush-blue{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/paint-brush-solid-blue.svg");
    }
  }
  &.fa-youtube{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/youtube-brands.svg");
    }
  }
  &.fa-youtube-black{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/youtube-brands--black.svg");
    }
  }
  &.fa-calendar-alt{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/calendar-alt-solid-white.svg");
    }
  }
  &.fa-redo-alt-white{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/redo-alt-solid-white.svg");
    }
  }
  &.fa-puzzle-piece-black{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/puzzle-piece-solid-black.svg");
    }
  }
  &.fa-bookmark{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/bookmark-solid.svg");
    }
  }
  &.fa-video{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/video.svg");
    }
  }
  &.fa-chart-line-white{
    &:before,
    &:after{
      width: 18px;
      background: url("../../images/icons/btn-icon/chart-line-regular-white.svg");
    }
  }
  &.fa-ring-regular--black{
    &:before,
    &:after{
      width: 18px;
      background: url("../../images/icons/btn-icon/life-ring-regular--black.svg");
    }
  }
  &.fa-life-ring-lite--white{
    &:before,
    &:after{
      width: 18px;
      background: url("../../images/icons/btn-icon/life-ring-lite--white.svg");
    }
  }
  &.fa-comment--white{
    &:before,
    &:after{
      width: 22px;
      background: url("../../images/icons/btn-icon/comment--white.svg");
    }
  }
  &.play-regular--white{
    &:before,
    &:after{
      width: 18px;
      background: url("../../images/icons/btn-icon/play-regular--white.svg");
    }
  }
  &.fa-cart-plus{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/cart-plus.svg");
    }
  }
  &.arrow-down-to-line-regular-white{
    &:before,
    &:after{
      background: url("../../images/icons/btn-icon/arrow-down-to-line-regular-white.svg");
    }
  }
}

.btn {
  position: relative;
  overflow: hidden;
  font-size: $main-text;
  min-height: 45px;
  line-height: 1;
  letter-spacing: 0.01em;
  font-family: $mulish;
  font-weight: $normal;
  text-align: center;
  display: inline-flex;
  align-items: center;
  padding: 12px 35px;
  border-radius: 50px;
  outline: 0;
  border: none;
  cursor: pointer;
  text-decoration: none;
  @include transition;
  -webkit-tap-highlight-color: transparent;

  @include has-icon;

  &:focus,
  &:active {
    outline: 0;
    border: none;
    text-decoration: none !important;
  }
  &:hover{
    text-decoration: none !important;
  }

  @include break-max($mob480) {
    font-size: 16px;
  }

  &.btn-icon-left{
    i{
      margin-right: 10px;
      margin-left: 0;
    }
  }

  &.btn-icon-right{
    i{
      margin-left: 10px;
      margin-right: 0;
    }
  }

  &--primary {
    background: $primary-red;
    font-size: $main-text;
    font-weight: $bold;
    color: $white;
    min-height: 45px;
    justify-content: space-around;

    i {
      margin-right: 15px;
    }

    &:hover{
      background: darken($primary-red, 10%);
      color: $white;
    }
    &:focus,
    &:active{
      color: $white;
    }
  }

  &--secondary {
    background: $dark-blue;
    font-size: $main-text;
    font-weight: $bold;
    color: $white;
    min-height: 45px;
    justify-content: space-around;
    i {
      margin-right: 15px;
    }

    &:hover{
      background: darken($dark-blue, 8%);
      color: $white;
    }
    &:focus,
    &:active{
      color: $white;
    }
  }

  &--alt {
    background: $primary-blue;
    font-weight: $bold;
    font-size: $main-text;
    color: $white;

    i {
      margin-left: 15px;
    }

    &:hover{
      background: darken($primary-blue, 10%);
      color: $white;
    }
    &:focus,
    &:active{
      color: $white;
    }
  }

  &--sm {
    background: $primary-sm;
    font-weight: $bold;
    font-size: $main-text;
    color: $dark-blue;

    i {
      margin-left: 15px;
    }

    &:hover{
      background: darken($primary-sm, 10%);
      color: $dark-blue;
    }
    &:focus,
    &:active{
      color: $dark-blue;
    }
  }

  &--transparent {
    background: transparent;
    font-size: $main-text;
    font-weight: $semibold;
    color: $dark-blue;

    &:hover,
    &:focus,
    &:active{
      color: $dark-blue;
    }
  }

  &--grey{
    background: $brand-grey;
    color: $dark-blue;
    font-size: $main-text;
    min-height: 45px;
    justify-content: space-around;

    i {
      margin-left: 15px;
    }
    &:hover,
    &:focus,
    &:active{
      background: darken($brand-grey, 2%);
      color: $dark-blue;
    }
  }

  &--white {
    background: $white;
    color: $dark-blue;
    font-size: $main-text;
    border: 1px solid $border-color;

    i {
      margin-left: 15px;
    }
    &:hover,
    &:focus,
    &:active{
      background: #d7dfef;
    }
  }

  span.ripple {
    position: absolute;
    display: block;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.23);
  }

  &:disabled,
  &[disabled]{
    border: 1px solid darken($color-neutral-light, 9%);
    background: rgba($color-neutral-light, 0.9);
    color: #888;
    pointer-events: none;
    cursor: not-allowed;
  }

}

.link {
  font-size: $main-text;
  line-height: 1;
  letter-spacing: 0.01em;
  font-family: $mulish;
  font-weight: $normal;
  text-align: center;
  display: inline-flex;
  align-items: center;
  padding: 0px 35px;
  background: transparent;
  border-radius: 50px;
  outline: none;
  border: none;
  cursor: pointer;
  @include transition;

  @include has-icon;

  svg{
    height: 16px;

    path{
      fill: $primary-blue;
    }
  }

  &--primary {
    color: $primary-blue;
    //text-decoration: underline;
    svg,
    i {
      path{
        fill: $primary-blue;
      }
      color: $primary-blue;
      text-decoration: none;
      margin-left: 10px;
      &:before {
        display: inline-block;
      }
    }
  }
  &--secondary {
    color: $dark-blue;
    background: $white;

    svg,
    i {

      path{
        fill: $dark-blue;
      }

      color: $dark-blue;
    }
  }

  &--icon-right:after {
    display: inline-flex;
    content: '';
    width: 24px;
    height: 18px;
    margin-left: 10px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  &.fa-long-arrow-alt-right-blue{
    &:before,
    &:after{
      width: 14px;
      background: url("../../images/icons/btn-icon/long-arrow-alt-right-solid-blue.svg");
    }
  }

  &:active{
    outline: none;
  }
}

.scroll-to-top{
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $second-brand-color;
  color: $primary-blue;
  right: -60px;
  bottom: 60px;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 0.9;
  z-index: 555;
  mix-blend-mode: multiply;

  //.ico{
  //  margin: 0;
  //  padding: 0;
  //  position: static;
  //  font-size: 24px;
  //  font-weight: 600;
  //}

  svg{
    height: 24px;

    path{
      fill: $primary-blue;
    }
  }

  &:hover{
    opacity: 1;

    svg{
      path{
        fill: #fff;
      }
    }
  }

  &.in{
    right: 25px;
  }

  @include break-max($tab){
    display: none !important;
  }
}