.section {

  @media (max-width: 1024px) {
    padding: 65px 0;
  }

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  &--first, &.main-top-section {
    padding-top: 125px;

    @media (max-width: 767px) {
      padding-top: 165px;
    }
  }
}

$grid-pd : 15px;
$grid-pd-added-mob: 5px;
$grid-pd-added-tab: 15px;


.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $grid-pd;
  padding-right: $grid-pd;

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-left: $grid-pd + $grid-pd-added-tab;
    padding-right: $grid-pd + $grid-pd-added-tab;
  }

  @media (max-width: 768px) {
    padding-left: $grid-pd + $grid-pd-added-mob;
    padding-right: $grid-pd + $grid-pd-added-mob;
  }
}