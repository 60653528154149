.popup--login-form{
  .modal__body{
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .login-form--card{
    text-align: center;

    .registration-field{
      .label{
        text-align: left;

        .ico{
          line-height: 1.4;
          display: inline-block;
          width: 45px;
          min-width: 45px;
          text-align: center;
          font-weight: 300;
          height: 16px;
          margin-top: 2px;
        }

        &.label--title{
          display: flex;
          color: $secondary-text-color;
        }

        &.registration-field-error{
          font-size: 14px;
          line-height: 1.3;
          padding-top: 5px;
          color: $primary-red;
          padding-left: 15px;
        }
      }
    }

    .login-form--forgot-pass{
      text-align: right;
      margin-bottom: 15px;

      @include break-max($mob){
        text-align: center;
      }

      .link{
        display: inline-flex;
        align-items: center;
        min-height: 30px;
        padding: 0;
      }
    }

    .login-form--social-links{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //margin: 0 -5px;
      padding-top: 50px;
      text-align: center;

      &:before{
        content: " ";
        display: block;
        position: absolute;
        top: 25px;
        left: 0;
        width: 100%;
        height: 1px;
        background: $second-brand-color;
      }

      .login-form--links-item{
        position: relative;
        display: inline-flex;
        //flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        //text-align: center;
        text-decoration: none;
        //color: $secondary-text-color;
        //width: calc(33.33% - 10px);
        //margin: 5px 5px 0 5px;
        //border-radius: 20px;
        //padding: 25px 15px 20px 15px;
        border-radius: 5px;
        min-width: 262px;
        padding: 5px 25px 5px 65px;
        height: 40px;
        margin-bottom: 12px;
        @include transition;

        &:last-child{
          margin-bottom: 0;
        }

        &.login-form--links-g{
          background: #fff;
          color: $dark-blue;
          box-shadow: 0px 3px 8px -2px rgba($dark-blue, 0.2);
          .login-form--social-icon{
            background: transparent;
          }

          &:hover, &:active, &:focus{
            box-shadow: 0px 4px 6px -2px rgba($dark-blue, 0.25);
          }
        }
        &.login-form--links-fc{
          background: #48659d;
          color: #fff;
          &:hover, &:active, &:focus{
            background: darken(#48659d, 3%);
          }
        }
        &.login-form--links-tw{
          background: #21a1e8;
          color: #fff;
          &:hover, &:active, &:focus{
            background: darken(#21a1e8, 3%);
          }
        }

        //@include break-max($mob){
        //  padding: 10px;
        //  justify-content: center;
        //}

        .login-form--social-icon{
          //font-size: 26px;
          //text-align: center;
          //margin-bottom: 10px;
          //color: #abbccc;
          //height: 26px;
          //
          //@include break-max($mob){
          //  margin-bottom: 0;
          //}

          position: absolute;
          top: 0;
          left: 0;
          width: 46px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba($dark-blue, 0.15);
          border-radius: 5px;

          svg{
            height: 22px;
          }
        }

        .social-links--txt{
          //@include break-max($mob){
          //  display: none;
          //}
        }


        &:hover{
          //color: $dark-blue;
          //box-shadow: 0px 10px 15px -4px rgba(45, 53, 71, 0.1);
          //
          //.ico{
          //  color: $primary-blue;
          //}
        }
      }
    }
  }
}

.client-account-card{
  .modal__content{
    .btn-bar{
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 -5px;

      .btn{
        margin: 5px;
        min-width: 180px;
        justify-content: center;
        padding: 5px 20px;
      }
    }
  }
}

