.sticky-anchors {
  &--with-shadow:not(.nav--sticky) {
    .sticky-anchors__link {
      border-bottom: none !important;
      border-radius: 20px;

      &:hover {
        box-shadow: -8px -8px 15px 0 rgba(45, 53, 71, 0.1);
      }
    }
  }

  .sticky-anchors__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -5px;
    @include transition;

    .sticky-anchors__item {
      flex: 1;
      margin: 0 5px 10px;

      @include break-max(1200px) {
        width: calc(25% - 10px);
        max-width: 300px;
        flex: auto;
      }

      @include break-max($mob) {
        width: calc(33.33% - 10px);
      }

      @include break-max(600px) {
        width: calc(50% - 10px);
      }

      .sticky-anchors__link {
        min-height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        text-decoration: none;
        padding: 30px 20px;
        font-family: $mulish;
        font-size: 16px;
        line-height: 1.2;
        border-bottom: 3px solid;
        border-color: transparent;
        color: $secondary-text-color;
        -webkit-tap-highlight-color: transparent;
        @include transition;

        .sticky-anchors__icon {
          width: 30px;
          font-size: 30px;
          font-weight: 300;
          margin-bottom: 15px;

          svg {
            path {
              fill: $secondary-text-color;
            }
          }
        }

        &:hover {
          color: $dark-blue;
          border-color: $primary-blue;

          .sticky-anchors__icon {
            color: $primary-blue;

            svg {
              path {
                fill: $primary-blue;
              }
            }
          }
        }

        &.active {
          color: $dark-blue;
          border-bottom: 3px solid $primary-blue;

          .sticky-anchors__icon {
            color: $primary-blue;
            svg {
              path {
                fill: $primary-blue;
              }
            }
          }
        }

        @include break-max($tab + 1) {
          background: $second-brand-color;
          height: 100%;
          font-size: 16px;
          border: none;

          &.active, &:hover {
            border: none;
            background: darken($second-brand-color, 4%);
          }

          &:hover {
            box-shadow: none;
          }
        }

        @include break-max($mob) {
          max-width: 100%;
          min-height: 50px;
          justify-content: center;
          padding: 15px 10px;
          border-radius: 10px;

          .sticky-anchors__icon {
            display: none;
          }
        }
      }
    }
  }

  &.nav--sticky {
    .sticky-anchors__list {
      position: fixed;
      top: 65px;
      background: #fff;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      max-width: 100%;
      z-index: 100;
      border-bottom: 2px solid $second-brand-color;

      .sticky-anchors__item {
        margin-bottom: 0;

        @include break-max(1200px) {
          max-width: 300px;
        }

        .sticky-anchors__link {
          font-size: 14px;
          padding: 15px 5px 5px 5px;
          height: 90px;
          min-height: 90px;
          border-radius: 0;
          border-bottom: 3px solid transparent;
          margin-bottom: -2px;
          overflow: hidden;

          @include break-max(1200px) {
            min-height: 45px;
            height: 45px;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 5px 15px;
            text-align: left;
          }

          .sticky-anchors__icon {
            width: 20px;
            font-size: 20px;
            margin-bottom: 10px;

            @include break-max(1200px) {
              margin: 0 10px 0 0;
              text-align: center;
              min-width: 28px;
            }
          }

          &.active {
            color: $dark-blue;
            border-bottom: 3px solid $primary-blue;

            .sticky-anchors__icon {
              color: $primary-blue;
              svg {
                path {
                  fill: $primary-blue;
                }
              }
            }
          }
        }
      }
    }

  }
}