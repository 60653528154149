.alert-message{
	color: $dark-blue;
	margin-bottom: 8px;
	position: relative;
	display: none;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	gap: 10px;
	font-size: 14px;
	line-height: 1.1;
	padding: 4px 0;

	&.in{
		display: flex;
	}

	&:last-child{
		margin-bottom: 0;
	}

	&:before{
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: "Font Awesome 5 Pro";
		font-weight: 400;
		font-size: 14px;
	}

	&.flash-warning{
		color: $primary-red;
		&:before{
			content: '';
			display: inline-block;
			height: 16px;
			width: 20px;
			line-height: 1;
			background: url("../../images/icons/btn-icon/exclamation-triangle-light--red.svg") no-repeat center;
			background-size: contain;
			position: relative;
			bottom: -0.3em;
			margin-right: 6px;
		}
	}
	&.flash-success{
		&:before{
			display: none;
			//color: #4CAF50;
			//content: '\f00c';
		}
	}
}

#form-modal-success{
	.alert-message{
		display: flex;
		font-size: 16px;
	}
}
