.accordion-list{
  margin-bottom: 20px;
  .accordion-card{
    .accordion-title{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      text-align: left;
      color: $dark-blue;
      font-weight: 500;
      padding: 5px 45px 5px 15px;
      min-height: 40px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &:after{
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        content: "\f078";
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 20px;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        color: $dark-blue;
       transition: all 0.4s ease;
      }

      &.active{
        &:after{
          transform: rotate(180deg);
        }
      }
    }
    .accordion-body{
      display: none;
      margin: 0;
      padding: 15px;
      text-align: left;
    }
  }
}