.app-links{
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 -5px;

  .app-links__link {
    display: block;
    max-width: 135px;
    width: 100%;
    margin: 5px;
    filter: invert(.5) sepia(1) hue-rotate(180deg) saturate(.6) brightness(1);
    transition: .3s ease;

    &:hover{
      filter: none;
    }
  }
}